/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import {rhythm} from "../utils/typography";

const Bio = () => {
    const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          description
        }
      }
    }
  `);

    const {author, description} = data.site.siteMetadata;
    return (
        <div
            style={{
                display: `flex`,
                marginBottom: rhythm(2.5),
            }}
        >
            <p>
                Written by {author}. {description}
            </p>
        </div>
    );
};

export default Bio;
